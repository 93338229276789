<template>
  <div class="search-properties">
    <div>
      <el-date-picker
          :clearable="false"
          class="button-left-status"
          style="width: 100px"
          value-format="yyyy"
          v-model="dataSearch.year"
          type="year"
          @change="searchByProperties"
          placeholder="Chọn năm"
      >
      </el-date-picker>


      <!-- chọn trạng học cho học sinh -->
      <el-select
          @change="handleChangeKidStatus1"
          style="width: 140px; margin-left: 5px"
          class="input-common"
          v-model="dataSearch.kidStatus"
          placeholder="Trạng thái học"
          clearable
      >
        <el-option
            v-for="item in kidStatusListExcel"
            :key="item.key"
            :value="item.key"
            :label="item.value"
        ></el-option>
      </el-select>
      <el-select
          @change="searchByProperties"
          style="width: 140px; margin-left: 5px"
          class="input-common"
          v-model="dataSearch.category"
          placeholder="Trạng thái"
          clearable
      >
        <el-option
            v-for="item in categories"
            :key="item.key"
            :value="item.value"
            :label="item.name"
        ></el-option>
      </el-select>
    </div>
    <div style="margin-top: 20px">
      <div>
        <el-row :gutter="50" style="margin:0 0 10px 0">
          <el-col :offset="6" :span="6" style="color: white">
            <div class="grid-content bg-purple">
              <div class="content-line-1 color-1">
                <i class="el-icon-chat-line-square"></i>
                <span v-if="this['loda'].sumBy(this.dataListOrder1) !== 0">Đã hoàn thành: {{
                    this['loda'].sumBy(this.dataListOrder1)
                  }}</span>
                <span v-else>Đã hoàn thành: 0</span>
              </div>
            </div>
          </el-col>
          <el-col :span="6" style="color: white"
          >
            <div class="grid-content bg-purple">
              <div class="content-line-1 color-2">
                <i class="el-icon-edit-outline"></i>
                <span v-if="this['loda'].sumBy(this.dataListOrder2) !== 0">Chưa hoàn thành: {{
                    this['loda'].sumBy(this.dataListOrder2)
                  }}</span>
                <span v-else>Chưa hoàn thành: 0</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <spinner-custom v-if="loadChart"/>
        <div v-else>
          <ChartBar
              v-if="showChartOrder"
              :checkLable="true"
              :chartData="dataConnectionOrder.chartData"
              :options="dataConnectionOrder.options"
          ></ChartBar>
        </div>
      </div>
      <div>
        <div style="margin-top: 50px" class="search-table">
          <el-date-picker
              :clearable="false"
              class="button-left-status"
              style="width: 100px"
              value-format="yyyy"
              v-model="dataSearchTuition.year"
              type="year"
              @change="searchByPropertiesTuition"
              placeholder="Chọn năm"
          >
          </el-date-picker>

          <!-- chọn trạng học cho học sinh -->
          <el-select
              @change="searchByPropertiesTuition"
              style="width: 140px"
              class="input-common"
              v-model="dataSearchTuition.kidStatus"
              placeholder="Trạng thái học"
              clearable
          >
            <el-option
                v-for="item in kidStatusListExcel"
                :key="item.key"
                :value="item.key"
                :label="item.value"
            ></el-option>
          </el-select>

          <el-select
              v-model="dataSearchTuition.category"
              placeholder="Trạng thái"
              style="width: 130px"
              clearable
              @change="searchByPropertiesTuition"
          >
            <el-option
                v-for="(item,index) in categories"
                :key="index"
                :value="item.value"
                :label="item.name"
            ></el-option>
          </el-select>
          <el-select
              v-model="dataSearchTuition.status"
              placeholder="Trạng thái kết chuyển"
              style="width: 180px"
              clearable
              @change="searchByPropertiesTuition"
          >
            <el-option
                v-for="(item,index) in kidsStatus"
                :key="index"
                :value="item.value"
                :label="item.name"
            ></el-option>
          </el-select>
        </div>
        <div>
          <el-row :gutter="50" style="margin: 30px 0 10px 0 ">
            <el-col :span="6" style="color: white">
              <div class="grid-content bg-purple">
                <div class="content-line-1 color-1">
                  <i class="el-icon-chat-line-square"></i>
                  <span v-if="this['loda'].sumBy(this.dataListTuitionRevenue1) !== 0">Thiếu thừa tháng truớc: {{
                      this['loda'].sumBy(this.dataListTuitionRevenue1) | formatCurrency
                    }}</span>
                  <span v-else>Thiếu thừa tháng truớc: 0 đ</span>
                </div>
              </div>
            </el-col>
            <el-col :span="6" style="color: white"
            >
              <div class="grid-content bg-purple">
                <div class="content-line-1 color-2">
                  <i class="el-icon-edit-outline"></i>
                  <span v-if="this['loda'].sumBy(this.dataListTuitionRevenue2) !== 0">Phải thu tháng này: {{
                      this['loda'].sumBy(this.dataListTuitionRevenue2) | formatCurrency
                    }}</span>
                  <span v-else>Phải thu tháng này: 0 đ</span>
                </div>
              </div>
            </el-col>
            <el-col :span="6" style="color: white"
            >
              <div class="grid-content bg-purple">
                <div class="content-line-1 color-3">
                  <i class="el-icon-edit-outline"></i>
                  <span v-if="this['loda'].sumBy(this.dataListTuitionRevenue3) !== 0">Đã thu: {{
                      this['loda'].sumBy(this.dataListTuitionRevenue3) | formatCurrency
                    }}</span>
                  <span v-else>Đã thu: 0 đ</span>
                </div>
              </div>
            </el-col>
            <el-col :span="6" style="color: white"
            >
              <div class="grid-content bg-purple">
                <div class="content-line-1 color-4">
                  <i class="el-icon-edit-outline"></i>
                  <span v-if="this['loda'].sumBy(this.dataListTuitionRevenue4) !== 0">Thiếu thừa còn lại: {{
                      this['loda'].sumBy(this.dataListTuitionRevenue4) | formatCurrency
                    }}</span>
                  <span v-else>Thiếu thừa còn lại: 0 đ</span>
                </div>
              </div>
            </el-col>
          </el-row>
          <spinner-custom v-if="loadChartTuitionRevenue"/>
          <div v-else>
            <ChartBar
                v-if="showChartOrder"
                :checkLable="true"
                :chartData="dataConnectionTuitionRevenue.chartData"
                :options="dataConnectionTuitionRevenue.options"
            ></ChartBar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartBar from "@/views/chartStatistical/chart/ChartBar.vue";
import GradeService from "@/services/GradeService";
import {kidStatusListExcel} from "@/constant/listCommon";


export default {
  components: {ChartBar},
  data() {
    return {
      loadChart: false,
      loadChartTuitionRevenue: false,
      dataSearch: {
        year: this.moment().format("YYYY"),
        category: "",
        kidStatus: ""
      },
      dataSearchTuition: {
        year: this.moment().format("YYYY"),
        category: "",
        status: null,
        kidStatus: null
      },
      categories: [
        {id: 1, value: "CLASS", name: "Tất cả lớp"},
        {id: 2, value: "GROUP", name: "Tất cả nhóm"},
      ],
      kidsStatus: [
        {id: "1", value: "MONEY_MONTH", name: "Sang tháng sau"},
        {id: 2, value: "MONEY_WALLET", name: "Không chuyển"},
      ],

      showChartOrder: true,
      dataConnectionOrder: {},
      dataConnectionTuitionRevenue: {},
      dataListOrder1: [],
      dataListOrder2: [],
      dataListOrder3: [],
      dataListOrder4: [],
      dataListOrder5: [],
      dataListOrder6: [],
      dataListOrder7: [],
      dataListOrder8: [],
      dataListTuitionRevenue1: [],
      dataListTuitionRevenue2: [],
      dataListTuitionRevenue3: [],
      dataListTuitionRevenue4: [],
      dataListTuitionRevenue5: [],
      dataListTuitionRevenue6: [],
      dataListTuitionRevenue7: [],
      dataListTuitionRevenue8: [],
      kidStatusListExcel
    }

  },
  async mounted() {
    await this.searchByProperties()
    await this.searchByPropertiesTuition()
  },
  methods: {
    fillDataOrder(dataListOrder3, dataListOrder4, dataListOrder5, dataListOrder6, dataListOrder7, dataListOrder8, category) {
      this.dataConnectionOrder = {
        chartData: {
          labels: this.$funcData.getListMonth(),
          datasets: [
            {
              label: "Đã hoàn thành",
              data: this.dataListOrder1,
              backgroundColor: "#6aa5e3",
            },
            {
              label: "Chưa hoàn thành",
              data: this.dataListOrder2,
              backgroundColor: "#fd4803",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: (() => {
              if (category === "CLASS") {
                return "Hóa đơn học phí tất cả lớp";
              } else if (category === "GROUP"){
                return "Hóa đơn học phí tất cả nhóm";
              } else {
                return "Hóa đơn học phí tất cả lớp và nhóm";
              }
            })(),
            fontSize: 20,
            fontColor: "#000",
          },
          tooltips: {
            enabled: true,
            useHTML: true,
            callbacks: {
              label: function (tooltipItem) {
                if (tooltipItem.datasetIndex === 0) {
                  let dataValue = tooltipItem.value;
                  let dataIndex = tooltipItem.index;
                  let dataValue1 = dataListOrder5[dataIndex]
                  let dataValue2 = dataListOrder6[dataIndex]
                  let dataValue3 = dataListOrder7[dataIndex]
                  let dataValue4 = dataListOrder8[dataIndex]
                  return ["Đã hoàn thành: " + dataValue, "Đóng đủ 100%: " + dataValue1, "Đóng thừa: " + dataValue2, "Đóng đủ thừa: " + dataValue3, "Đóng đủ thiếu: " + dataValue4]
                }
                if (tooltipItem.datasetIndex === 1) {
                  let dataValue = Math.abs(tooltipItem.value);
                  let dataIndex = tooltipItem.index;
                  let dataValue1 = dataListOrder3[dataIndex]
                  let dataValue2 = dataListOrder4[dataIndex]
                  return ["Chưa hoàn thành: " + dataValue, "Chưa đóng 100%: " + dataValue1, "Chưa đóng đủ: " + dataValue2]
                }
              },
            }
          },
          plugins: {
            datalabels: {
              color: "black",
              anchor: "end",
              align: "end",
              formatter: (value) => {
                return value > 0 ? value : "";
              },
            },
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
    },

    fillDataTuitionRevenue(dataListTuitionRevenue5, dataListTuitionRevenue6, dataListTuitionRevenue7, dataListTuitionRevenue8, category) {
      this.dataConnectionTuitionRevenue = {
        chartData: {
          labels: this.$funcData.getListMonth(),
          datasets: [
            {
              label: "Thiếu thừa tháng trước",
              data: this.dataListTuitionRevenue1,
              backgroundColor: "#6aa5e3",
            },
            {
              label: "Phải thu tháng này",
              data: this.dataListTuitionRevenue2,
              backgroundColor: "#fd4803",
            },
            {
              label: "Đã thu tháng này",
              data: this.dataListTuitionRevenue3,
              backgroundColor: "#34e527",
            },
            {
              label: "Thiếu thừa còn lại",
              data: this.dataListTuitionRevenue4,
              backgroundColor: "#ac20f8",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: (() => {
              if (category === "CLASS") {
                return "Doanh thu học phí tất cả lớp";
              } else if (category === "GROUP"){
                return "Doanh thu học phí tất cả nhóm";
              } else {
                return "Doanh thu học phí tất cả lớp và nhóm";
              }
            })(),
            fontSize: 20,
            fontColor: "#000",
          },
          tooltips: {
            enabled: true,
            useHTML: true,
            callbacks: {
              label: function (tooltipItem) {
                if (tooltipItem.datasetIndex === 0) {
                  let dataValue = Math.abs(tooltipItem.value);
                  dataValue = Number(dataValue).toLocaleString("vi", {style: "currency", currency: "VND"});
                  if (tooltipItem.value > 0) {
                    return ["Thiếu thừa tháng trước: " + dataValue, "Ghi chú: PH còn thừa."]
                  } else {
                    return ["Thiếu thừa tháng trước: " + "(" + dataValue + ")", "Ghi chú: PH còn thiếu."]
                  }
                }
                if (tooltipItem.datasetIndex === 1) {
                  let dataValue = Math.abs(tooltipItem.value);
                  let dataIndex = tooltipItem.index;
                  let dataValue1 = Math.abs(dataListTuitionRevenue7[dataIndex])
                  let dataValue2 = Math.abs(dataListTuitionRevenue8[dataIndex])
                  dataValue = Number(dataValue).toLocaleString("vi", {style: "currency", currency: "VND"});
                  dataValue1 = Number(dataValue1).toLocaleString("vi", {style: "currency", currency: "VND"});
                  dataValue2 = Number(dataValue2).toLocaleString("vi", {style: "currency", currency: "VND"});
                  if (tooltipItem.value > 0) {
                    return ["Tổng phải thu: " + dataValue, "Phải thu: " + dataValue1, "Phải chi: " + "(" + dataValue2 + ")", "Ghi chú: Tổng NT dự thu."]
                  } else {
                    return ["Tổng phải chi: " + dataValue, "Phải thu: " + dataValue1, "Phải chi: " + "(" + dataValue2 + ")", "Ghi chú: Tổng NT dự chi."]
                  }
                }
                if (tooltipItem.datasetIndex === 2) {
                  let dataValue = Math.abs(tooltipItem.value);
                  let dataIndex = tooltipItem.index;
                  let dataValue1 = Math.abs(dataListTuitionRevenue5[dataIndex])
                  let dataValue2 = Math.abs(dataListTuitionRevenue6[dataIndex])
                  dataValue = Number(dataValue).toLocaleString("vi", {style: "currency", currency: "VND"});
                  dataValue1 = Number(dataValue1).toLocaleString("vi", {style: "currency", currency: "VND"});
                  dataValue2 = Number(dataValue2).toLocaleString("vi", {style: "currency", currency: "VND"});
                  if (tooltipItem.value > 0) {
                    return ["Tổng đã thu: " + dataValue, "Đã thu: " + dataValue1, "Đã chi: " + "(" + dataValue2 + ")", "Ghi chú: NT đã thu."]
                  } else {
                    return ["Tổng đã thu: " + dataValue, "Đã thu: " + dataValue1, "Đã chi: " + "(" + dataValue2 + ")", "Ghi chú: NT đã chi."]
                  }
                }
                if (tooltipItem.datasetIndex === 3) {
                  let dataValue = Math.abs(tooltipItem.value);
                  dataValue = Number(dataValue).toLocaleString("vi", {style: "currency", currency: "VND"});
                  if (tooltipItem.value > 0) {
                    return ["Thiếu thừa còn lại: " + dataValue, "Ghi chú: PH còn thừa."]
                  } else {
                    return ["Thiếu thừa còn lại: " + "(" + dataValue + ")", "Ghi chú: PH còn thiếu."]
                  }
                }
              },
            }
          },
          plugins: {
            datalabels: {
              color: "black",
              anchor: (ctx) => (ctx.datasetIndex === 0 ? "end" : (ctx.dataset.data[ctx.dataIndex] > 0 ? "end" : "start")),
              align: (ctx) => (ctx.datasetIndex === 0 ? "end" : (ctx.dataset.data[ctx.dataIndex] > 0 ? "end" : "start")),
              formatter: (value) => {
                if (value !== 0) {
                  return value.toLocaleString("vi", {style: "currency", currency: "VND"});
                }
                return "";
              },
            },
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  callback: (value) => {
                    return ` ${value.toLocaleString("vi", {style: "currency", currency: "VND"})}`;
                  },
                },
              },
            ],
          },
        },
      };
    },

   async searchByProperties() {
      this.loadChart = true
     await GradeService.getAllChartOrder(this.dataSearch.year, this.dataSearch.category, this.dataSearch.kidStatus).then((response) => {
        this.loadChart = false;
        this.resetListChartOrder()
        for (let i = 0; i < response.data.length; i++) {
          const e = response.data[i];
          this.dataListOrder1.push(e.complete)
          this.dataListOrder2.push(e.noComplete)
          this.dataListOrder3.push(e.noMoneyPayment)
          this.dataListOrder4.push(e.noMoneyPaymentFull)
          this.dataListOrder5.push(e.yesMoneyPaymentFull)
          this.dataListOrder6.push(e.yesPaymentOverallMoney)
          this.dataListOrder7.push(e.yesMoneyNextMonth)
          this.dataListOrder8.push(e.noPaymentNextMonth)
        }
        this.fillDataOrder(this.dataListOrder3, this.dataListOrder4, this.dataListOrder5, this.dataListOrder6, this.dataListOrder7, this.dataListOrder8,this.dataSearch.category)
      }).catch(() => {
        this.$message({
          message: "Failed to get the data",
          type: "error",
        });
        this.loadChart = false;
      })

    },

    async searchPropertiesOfTuitionRevenueByClass(request) {
      this.loadChartTuitionRevenue = true
      await GradeService.getAllChartTuitionRevenueByClass(request).then((response) => {
        this.loadChartTuitionRevenue = false
        this.resetListChart()
        for (let i = 0; i < response.data.length; i++) {
          const e = response.data[i];
          this.dataListTuitionRevenue1.push(e.beforeMoneySchool);
          this.dataListTuitionRevenue2.push(e.sumMoneySchool);
          this.dataListTuitionRevenue3.push(e.collectedMoneySchool);
          this.dataListTuitionRevenue4.push(e.afterMoneySchool);
          this.dataListTuitionRevenue5.push(e.actualCollectSchool);
          this.dataListTuitionRevenue6.push(e.actualSpendSchool);
          this.dataListTuitionRevenue7.push(e.expectedCollectSchool);
          this.dataListTuitionRevenue8.push(e.expectedSpendSchool);
        }
        this.fillDataTuitionRevenue(this.dataListTuitionRevenue5, this.dataListTuitionRevenue6, this.dataListTuitionRevenue7, this.dataListTuitionRevenue8,this.dataSearchTuition.category)
      }).catch(() => {
        this.$message({
          message: "Failed to get the data",
          type: "error",
        });
        this.loadChartTuitionRevenue = false
      })
    },

    async searchPropertiesOfTuitionRevenueByGroup(request) {
      this.loadChartTuitionRevenue = true
      await GradeService.getAllChartTuitionRevenueByGroup(request).then((response) => {
        this.loadChartTuitionRevenue = false
        this.resetListChart()
        for (let i = 0; i < response.data.length; i++) {
          const e = response.data[i];
          this.dataListTuitionRevenue1.push(e.beforeMoneySchool);
          this.dataListTuitionRevenue2.push(e.sumMoneySchool);
          this.dataListTuitionRevenue3.push(e.collectedMoneySchool);
          this.dataListTuitionRevenue4.push(e.afterMoneySchool);
          this.dataListTuitionRevenue5.push(e.actualCollectSchool);
          this.dataListTuitionRevenue6.push(e.actualSpendSchool);
          this.dataListTuitionRevenue7.push(e.expectedCollectSchool);
          this.dataListTuitionRevenue8.push(e.expectedSpendSchool);
        }
        this.fillDataTuitionRevenue(this.dataListTuitionRevenue5, this.dataListTuitionRevenue6, this.dataListTuitionRevenue7, this.dataListTuitionRevenue8, this.dataSearchTuition.category)
      }).catch(() => {
        this.$message({
          message: "Failed to get the data",
          type: "error",
        });
        this.loadChartTuitionRevenue = false
      })
    },

   async searchPropertiesOfTuitionRevenueAll(request) {
      this.loadChartTuitionRevenue = true
      await GradeService.getAllChartTuitionRevenueAll(request).then((response) => {
        this.loadChartTuitionRevenue = false
        this.resetListChart()
        for (let i = 0; i < response.data.length; i++) {
          const e = response.data[i];
          this.dataListTuitionRevenue1.push(e.beforeMoneySchool);
          this.dataListTuitionRevenue2.push(e.sumMoneySchool);
          this.dataListTuitionRevenue3.push(e.collectedMoneySchool);
          this.dataListTuitionRevenue4.push(e.afterMoneySchool);
          this.dataListTuitionRevenue5.push(e.actualCollectSchool);
          this.dataListTuitionRevenue6.push(e.actualSpendSchool);
          this.dataListTuitionRevenue7.push(e.expectedCollectSchool);
          this.dataListTuitionRevenue8.push(e.expectedSpendSchool);
        }
        this.fillDataTuitionRevenue(this.dataListTuitionRevenue5, this.dataListTuitionRevenue6, this.dataListTuitionRevenue7, this.dataListTuitionRevenue8, this.dataSearchTuition.category)
      }).catch(() => {
        this.$message({
          message: "Failed to get the data",
          type: "error",
        });
        this.loadChartTuitionRevenue = false
      })
    },

    searchByPropertiesTuition() {
      if (this.dataSearchTuition.status === ''){
        this.dataSearchTuition.status = null
      }
      if (this.dataSearchTuition.kidStatus === "") {
        this.dataSearchTuition.kidStatus = null
      }
      const request = {
        year: this.dataSearchTuition.year,
        transactionEntry: this.dataSearchTuition.status,
        kidStatus: this.dataSearchTuition.kidStatus
      }
      if (this.dataSearchTuition.category === "CLASS") {
        this.searchPropertiesOfTuitionRevenueByClass(request);
      } else if (this.dataSearchTuition.category === "GROUP") {
        this.searchPropertiesOfTuitionRevenueByGroup(request);
      } else {
        this.searchPropertiesOfTuitionRevenueAll(request);
      }
    },

    resetListChartOrder() {
      this.dataListOrder1 = []
      this.dataListOrder2 = []
      this.dataListOrder3 = []
      this.dataListOrder4 = []
      this.dataListOrder5 = []
      this.dataListOrder6 = []
      this.dataListOrder7 = []
      this.dataListOrder8 = []
    },

    resetListChart() {
      this.dataListTuitionRevenue1 = []
      this.dataListTuitionRevenue2 = []
      this.dataListTuitionRevenue3 = []
      this.dataListTuitionRevenue4 = []
      this.dataListTuitionRevenue5 = []
      this.dataListTuitionRevenue6 = []
      this.dataListTuitionRevenue7 = []
      this.dataListTuitionRevenue8 = []
    },
    handleChangeKidStatus1() {
      this.searchByProperties()

    },

  },
}
</script>

<style lang="scss" scoped>
.content-line {
  padding: 12px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.content-line-1 {
  padding: 6px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.color-1 {
  background: #6aa5e3;
}

.color-2 {
  background: #fd4803;
}

.color-3 {
  background: #34e527;
}

.color-4 {
  background: #ac20f8;
}

.search-table {
  margin-bottom: 20px;
  z-index: 1;
  display: flex;
  width: 100%;
  gap: 0.3rem;
  flex-wrap: wrap;
}


</style>